//navbar and footer will be added later.
import { useState } from "react"
import Card from "../components/card"

export default function Projects() {
  const allProjects = [
    {
      year: "all",
      projects: [
        {
          name: "Çekiliş QR Sitesi",
          image: "/card-images/projects/qr.png",
          link: "https://qrcodegenerator-blue.vercel.app",
        },
        {
          name: "SMS'24",
          image: "/card-images/projects/sms24.png",
          link: "https://sms.acmhacettepe.com",
        },
        {
          name: "Geyik Tanıtım Sitesi",
          image: "/card-images/projects/geyik-tanitim.png",
          link: "https://geyik.acmhacettepe.com",
        },
        { name: "Tenis Rezervasyon Sistemi", image: "/temp.png", link: "" },
        { name: "HUISA", image: "/temp.png", link: "" },
        {
          name: "Geyik",
          image: "/card-images/projects/geyik.png",
          link: "https://play.google.com/store/apps/details?id=com.acm.geyik_app",
        },
        {
          name: "HUPROG24",
          image: "/card-images/projects/huprog.png",
          link: "https://huprog.acmhacettepe.com",
        },
        {
          name: "HUJAM23",
          image: "/card-images/projects/hujam.png",
          link: "https://huprog.acmhacettepe.com",
        },
     
        { name: "TOONINGO", image: "/temp.png", link: "" },
        { name: "FIDES", image: "/temp.png", link: "" },
        {
          name: "Gelişim V10",
          image: "/card-images/projects/gelisim.png",
          link: "https://gelisim.acmhacettepe.com",
        },
        {
          name: "ACSDays'24",
          image: "/card-images/projects/acsdays.png",
          link: "https://acsdays.acmhacettepe.com",
        },
        {
          name: "ACM Kart",
          image: "/card-images/projects/kart.png",
          link: "https://card.acmhacettepe.com",
        },
      ],
    },
  ]

  const [date, setDate] = useState("all")

  const currentProjects = allProjects.find((project) => project.year === date)

  return (
    <div
      id="projects"
      className=" min-h-screen bg-[#09090A] text-white pb-12 px-4 overflow-hidden"
    >
      <main className="py-5 flex flex-col justify-center">

      <div className="relative flex items-center">
            <div className="absolute lg:left-[360px] lg:top-[295px] left-40 top-[275px] transform translate-y-0 z-10">
              <img
                src="/assets/ai.svg"
                alt="Logo Left"
                className="lg:w-[190px] lg:h-[190px] w-[80px] h-[70px]"
              />
            </div>
            <div className="absolute lg:left-[125px] lg:top-[180px] left-6 top-[227px] transform translate-y-0 z-10">
              <img
                src="/assets/game.svg"
                alt="Logo Left"
                className="lg:w-[190px] lg:h-[190px] w-[80px] h-[70px]"
              />
            </div>
        <section
          id="header"
          className="lg:ml-20 ml-0 font-bold lg:pb-80 py-64 pr-72 mr-72 lg:text-9xl md:text-9xl text-5xl pl-4"
        >
          Projelerimiz
        </section>
        </div>
        <section
          id="content"
          className="grid lg:grid-cols-3 grid-cols-1 xs:grid-cols-2 lg:gap-12 gap-4 mx-auto lg:px-0 md:px-0 xs:px-2" 
        >
          {currentProjects &&
            currentProjects.projects.map((project) => (
              <Card
                name={project.name}
                image={project.image}
                link={project.link}
              ></Card>
            ))}
        </section>
      </main>
    </div>
  )
}
