import React from "react";

const Footer = () => {
  return (

    <footer className="bg-[#09090A] text-white relative mt-auto h-[250px] px-7">

      <div className="container mx-auto flex justify-between items-center h-full">
        
        {/* Mobile View */}
        <div className="md:hidden flex flex-col items-start w-full">
          <div className="flex items-center justify-between w-full">
            <img
              src="/assets/logo.png"
              alt="Logo"
              className="w-[157px] h-[69px]"
            />
            <div className="flex items-center">
              <img
                src="/assets/Vector.png"
                alt="Icon"
                className="w-[20px] h-[16px] transform -scale-x-100 ml-4"
              />
              <p className="font-medium text-base leading-6 text-white ml-2">
                Bize Ulaşın
              </p>
            </div>
          </div>
          
          <p className="mt-4 text-center w-full font-inter text-sm leading-5 text-white">
            © 2024 ACM Hacettepe AR-GE Birimi
          </p>
        </div>

        {/* Desktop View (Unchanged) */}
        <div className="hidden md:flex justify-between items-center w-full">
  <div className="flex flex-col items-start relative">
    <img
      src="/assets/logo.png"
      alt="Logo"
      className="w-[157px] h-[69px]"
    />
    <div className="flex items-center mt-12">
      <img
        src="/assets/Vector.png"
        alt="Icon"
        className="w-[20px] h-[16px] transform -scale-x-100 mr-2"
      />
      <p className="font-medium text-base leading-6 text-white">
        Bize Ulaşın
      </p>
    </div>
  </div>

  <div className="flex justify-center items-center space-x-10 mt-32 ml-32">
    <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
      <img src="/assets/TwitterX.png" alt="Twitter" className="w-6 h-6" />
    </a>
    <a href="https://instagram.com/" target="_blank" rel="noopener noreferrer">
      <img src="/assets/Instagram.png" alt="Instagram" className="w-6 h-6" />
    </a>
    <a href="https://facebook.com/" target="_blank" rel="noopener noreferrer">
      <img src="/assets/Facebook.png" alt="Facebook" className="w-6 h-6" />
    </a>
    <a href="https://linkedin.com/" target="_blank" rel="noopener noreferrer">
      <img src="/assets/Linkedin.png" alt="LinkedIn" className="w-6 h-6" />
    </a>
    <a href="https://youtube.com/" target="_blank" rel="noopener noreferrer">
      <img src="/assets/Youtube.png" alt="Youtube" className="w-6 h-6" />
    </a>
  </div>

  <p className="absolute left-[80vw] top-[75%] transform -translate-x-1/2 -translate-y-1/2 font-inter text-sm leading-5 text-white">
  © 2024 ACM Hacettepe AR-GE Birimi
</p>


          <div className="flex flex-row items-start justify-end gap-8">
            <a
              href="/projects"
              className="hover:text-gray-400 font-poppins text-sm leading-7 text-white"
            >
              Projelerimiz
            </a>
            <a
              href="/about"
              className="hover:text-gray-400 font-poppins text-sm leading-7 text-white"
            >
              Biz Kimiz?
            </a>
            <a
              href="/competitions"
              className="hover:text-gray-400 font-poppins text-sm leading-7 text-white"
            >
              Yarışma Ekiplerimiz
            </a>
            <a
              href="/contact"
              className="hover:text-gray-400 font-poppins text-sm leading-7 text-white"
            >
              İletişim
            </a>
            <a
              href="/join-us"
              className="hover:text-gray-400 font-poppins text-sm leading-7 text-white"
            >
              Bize Katıl
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};


export default Footer;

