import React, { useRef, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const About = () => {
    const sliderRef = useRef(null);
    const secondSliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [secondCurrentSlide, setSecondCurrentSlide] = useState(0);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    };

    const secondSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        beforeChange: (oldIndex, newIndex) => setSecondCurrentSlide(newIndex),
    };

    const handleThumbnailClick = (index) => {
        sliderRef.current.slickGoTo(index); 
    };

    const handleSecondThumbnailClick = (index) => {
        secondSliderRef.current.slickGoTo(index); 
    };

    return (
        <div>

            <div className="bg-[#09090A] flex flex-col justify-center items-start min-h-[80vh] px-4 md:px-16 overflow-hidden">

                <div className="relative flex items-center">
                    <div className="absolute lg:left-[160px] lg:top-[310px] left-[63px] top-[290px] transform translate-y-0 z-10">
                    <img
                        src="/assets/info.svg"
                        alt="Logo Left"
                        className="lg:w-[160px] lg:h-[160px] w-[70px] h-[70px]"
                    />
                    </div>
                    <div className="text-white lg:ml-[50px] ml-2 font-bold lg:py-70 py-64 lg:pr-80 lg:mr-80 pr-32 lg:text-9xl text-6xl whitespace-nowrap">
                        Biz Kimiz?
                    </div>
                </div>
            </div>
            {/* first text */}
            <div className="bg-[#09090A] text-white py-8 md:py-16">
                <div className="container  mx-auto text-left px-4 md:px-10 w-full md:w-[90%] ">

                    <div className="flex flex-col md:flex-row items-center mb-8 gap-4" style={{ height: 'auto' }}>
                        <img src="logos/navbar-about-home/acmhacettepe-logo.png" alt="ACM Logo" className="h-20 md:h-28" />
                        <span className="text-4xl font-bold">X</span>
                        <img src="logos/navbar-about-home/arge-logo.png" alt="Arge Logo" className="h-20 md:h-28" />
                    </div>

                    <div className="flex flex-col md:flex-row gap-4">
                        <p className="text-left text-[4vw] md:text-[24px] flex-[3]" style={{
                            fontWeight: '400',
                            lineHeight: '1.7',
                        }}>
                            Topluluğumuzun proje odaklı, öğrenmeye ve öğretmeye istekli insanları bünyesinde barındıran birimidir. 
                            Başta yazılım olmak üzere, mekanik, elektronik ve tasarım gibi çeşitli alanlara ilgisi ve bilgisi olan kişileri bir araya getirir. 
                            Kendilerine hem mentor hem de takım arkadaşları edinme imkanı sunularak, birlikte eğlenerek projeler geliştirebilecekleri ve takım olarak çalışmayı deneyim edebilecekleri profesyonel bir ortam sağlamaktadır.
                            Bu birimde, hem topluluğumuzun ihtiyacı olan site ve uygulamaları geliştirilmekte hem de Teknofest, Google Solution Challenge gibi çeşitli proje yarışmalarına katılmaktayız. Aynı zamanda fikir bazlı projeler de geliştirmekte ve bu projelerin girişimleştirilmesi konusunda da aktif rol oynamaktayız.
                        </p>


                    </div>

                    {/* second text */}
                    <div className="flex flex-col md:flex-row gap-4 mt-16">
                        <p className="text-left text-[4vw] md:text-[24px] flex-[3]" style={{
                            fontWeight: '400',
                            lineHeight: '1.7',
                        }}>
                            ACM (Association for Computing Machinery), bilgisayar mühendisliği bilimini bir araya getirmek için 1947'de kurulmuş ve gün geçtikçe büyüyerek dünya çapında tanınan bir kuruluştur.
                             Merkezi New York şehrinde olan ACM; dünya genelinde çeşitli şubeleri, profesyonel kulüpleri, özel araştırma gruplarını ve öğrenci kollarını bünyesinde barındırmaktadır.
                            ACM, mesleki akademik yayın ve toplantı faaliyetlerinin yanı sıra bilgisayar bilimlerinin Nobel'i sayılan Turing Ödülü'nü veren kuruluş olarak da bilinir.
                             Dünya genelini kapsayan üyelik sistemi sayesinde bilişim teknolojisinin çeşitli alanları ile uğraşan profesyonellere ve öğrencilere kaynak sağlamaktadır.
                        </p>

                        <div className="p-[4px] flex-1 max-w-full md:max-w-[40%]" style={{
                            background: 'linear-gradient(to right, #BA00FF 0%, #3CA0FF 100%)',
                            borderRadius: '10px'
                        }}>

                            <div className="bg-[#09090A] p-4 rounded-md">

                                <Slider {...settings} ref={sliderRef}>
                                    <div className="aspect-w-16 aspect-h-9"> 
                                        <img src="/assets/syn.JPG" alt="Image1" className="w-full h-full object-cover" />
                                    </div>
                                    <div className="aspect-w-16 aspect-h-9">
                                        <img src="/assets/syn2.png" alt="Image2" className="w-full h-full object-cover" />
                                    </div>
                                    <div className="aspect-w-16 aspect-h-9">
                                        <img src="/assets/stant.jpg" alt="Image3" className="w-full h-full object-cover" />
                                    </div>
                                </Slider>
                            </div>

                            <div className="flex flex-row justify-center gap-2 mt-4">
                                <img
                                    src="/assets/syn.JPG"
                                    alt="Thumbnail1"
                                    className={`w-16 h-16 object-cover rounded-lg cursor-pointer transition-transform transform ${currentSlide === 0 ? 'scale-105 border-4 border-blue-500' : 'border border-gray-500'}`}
                                    onClick={() => handleThumbnailClick(0)}
                                />
                                <img
                                    src="/assets/syn2.png"
                                    alt="Thumbnail2"
                                    className={`w-16 h-16 object-cover rounded-lg cursor-pointer transition-transform transform ${currentSlide === 1 ? 'scale-105 border-4 border-blue-500' : 'border border-gray-500'}`}
                                    onClick={() => handleThumbnailClick(1)}
                                />
                                <img
                                    src="/assets/stant.jpg"
                                    alt="Thumbnail3"
                                    className={`w-16 h-16 object-cover rounded-lg cursor-pointer transition-transform transform ${currentSlide === 2 ? 'scale-105 border-4 border-blue-500' : 'border border-gray-500'}`}
                                    onClick={() => handleThumbnailClick(2)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;