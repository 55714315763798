export default function Card({ name, image, link }) {
  return (
    <div className="bg-gradient-to-br from-[#BA00FF] to-[#3CA0FF] lg:max-w-[500px] lg:max-h-[500px] max-w-[300px] p-1 flex justify-center items-center">

      <div className="flex flex-col text-white lg:w-[400px] lg:h-[400px] w-[300px] h-[300px] overflow-hidden bg-[#09090A] lg:text-2xl text-xl font-bold">

        <div id="upper-section">
          <img
            src={image} //image resolution ratio must be 4:3
            alt="sdsd"
            className="lg:w-[400px] lg:h-[300px] w-[300px] h-[225px]"
          />
        </div>
        <div id="lower-section" className="flex flex-col justify-end pt-4">
          <div className="flex flex-row">
            <p className="ml-2 md:ml-4 lg:mt-2">{name}</p>
            <a
              href={link}
              target="_blank"
              className="ml-auto mr-4 lg:block hidden"
            >
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.52513 30.5251L1.05025 33L6 37.9497L8.47487 35.4749L3.52513 30.5251ZM38.5 4C38.5 2.067 36.933 0.499999
 35 0.5L3.5 0.499999C1.56701 0.499999 1.72266e-06 2.067 1.72266e-06 4C1.72266e-06 5.93299 1.56701 7.5 3.5 7.5L31.5
  7.5L31.5 35.5C31.5 37.433 33.067 39 35 39C36.933 39 38.5 37.433 38.5 35.5L38.5 4ZM8.47487 35.4749L37.4749 6.47487L32.5251 
  1.52513L3.52513 30.5251L8.47487 35.4749Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
