import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
    const [hovered, setHovered] = useState(false);
    
    return (
        <div className="bg-[#09090A] text-white py-16 px-4  sm:px-10 flex flex-col items-left justify-center min-h-[80vh]">
            <h1
                className="font-bold ml-4 max-w-5xl text-left text-3xl sm:text-6xl md:text-6xl lg:text-7xl font-semibold leading-tight mb-8" 
            >
                ACM Hacettepe <br /> 
                <span>Ar-Ge </span>
                <span className="gradient-text">Birimimize Hoş Geldiniz!</span>
            </h1>

            <div className="flex justify-center hidden">
                <Link
                    to="/join-us"
                    className={`text-white text-lg flex justify-center items-center transition-all duration-300 ease-out
                        w-40 h-12 sm:w-60 sm:h-14`} 
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    style={{
                        borderRadius: '14px',
                        padding: '2px',
                        background: 'linear-gradient(105.34deg, rgba(186, 0, 255, 1) 0%, rgba(60, 160, 255, 1) 100%)',
                        transition: 'background 300ms ease-in-out',
                    }}
                >
                    <div
                        style={{
                            background: hovered ? '#09090A' : 'transparent', 
                            width: '100%',
                            height: '100%',
                            borderRadius: '14px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            transition: 'background 300ms ease-in-out',
                        }}
                    >
                        Bize Katıl
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default Hero;

