import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi'; // for mobile device 

const Navbar = () => {
    const [hovered, setHovered] = useState(false);
    const [isOpen, setIsOpen] = useState(false); 

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="bg-[#09090A] p-6">
            <div className="container mx-auto flex justify-between items-center">
                <div>
                    <Link to="/">
                        <img src="logos/navbar-about-home/arge-logo.png" alt="Arge Logo" className="h-12" />
                    </Link>
                </div>

                <div className="lg:hidden flex items-center">
                    <button onClick={toggleMenu} className="text-white focus:outline-none">
                        {isOpen ? <FiX size={28} /> : <FiMenu size={28} />}
                    </button>
                </div>

                {/* website menu */}
                <div className="hidden lg:flex items-center space-x-8">
                    <ul className="flex space-x-8 text-white text-lg">
                        <li><Link to="/about" className="hover:text-purple-500">Biz Kimiz?</Link></li>
                        <li><Link to="/projects" className="hover:text-purple-500">Projelerimiz</Link></li>
                        <li><Link to="/competitions" className="hover:text-purple-500">Yarışma Ekiplerimiz</Link></li>
                        <li><Link to="/contact" className="hover:text-purple-500">İletişim</Link></li>
                    </ul>

                    <Link
                        to="/join-us"
                        className="text-white text-lg flex justify-center items-center transition-all duration-300 ease-out relative hidden"
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        style={{
                            width: '137px',
                            height: '44px',
                            padding: '2px',
                            borderRadius: '14px',
                            background: 'linear-gradient(105.34deg, #BA00FF 0%, #3CA0FF 100%)',
                            position: 'relative',
                            transition: 'background 300ms ease-in-out',
                        }}
                    >
                        <div
                            style={{
                                background: hovered ? '#09090A' : 'transparent',
                                width: '100%',
                                height: '100%',
                                borderRadius: '14px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: 'background 300ms ease-in-out',
                            }}
                        >
                            Bize Katıl
                        </div>
                    </Link>

                </div>
            </div>

            <div
                className="h-1 mt-5"
                style={{
                    background: 'linear-gradient(to right, #BA00FF 0%, #3CA0FF 100%)'
                }}>
            </div>
            {/* Mobile menu */}
            <div
                className={`lg:hidden ${isOpen ? 'block' : 'hidden'} mt-5 flex flex-col items-center space-y-4 text-white text-lg`}>
                    <Link to="/about" className="hover:text-purple-500">Biz Kimiz?</Link>
                <Link to="/projects" className="hover:text-purple-500">Projelerimiz</Link>
                <Link to="/competitions" className="hover:text-purple-500">Yarışma Ekiplerimiz</Link>
                <Link to="/contact" className="hover:text-purple-500">İletişim</Link>
                <Link
                    to="/join-us"
                    className="text-white text-lg flex justify-center items-center transition-all duration-300 ease-out relative"
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    style={{
                        borderRadius: '14px',
                        width: '318px',
                        height: '40px',
                        padding: '2px',
                        background: 'linear-gradient(105.34deg, #BA00FF 0%, #3CA0FF 100%)',
                        transition: 'background 300ms ease-in-out',
                    }}
                >
                    <div
                        style={{
                            background: hovered ? 'black' : 'transparent',
                            borderRadius: '14px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            transition: 'background 300ms ease-in-out',
                        }}
                    >
                        Bize Katıl
                    </div>
                </Link>
            </div>
        </nav>
    );
};

export default Navbar;
