import React, { useState } from "react"

const Contact = () => {
  const [hovered, setHovered] = useState(false)

  return (

    <div className="min-h-screen bg-[#09090A] flex flex-col items-center justify-center">
      <div className="relative flex items-center">
            <div className="absolute lg:left-[-170px] lg:top-[207px] left-8 top-[237px] transform translate-y-0 z-10">
              <img
                src="/assets/at.png"
                alt="Logo Left"
                className="lg:w-[130px] lg:h-[130px] w-[60px] h-[60px]"
              />
            </div>
            <div className="absolute lg:left-[175px] lg:top-[292px] left-40 top-[285px] transform translate-y-0 z-10">
              <img
                src="/assets/mail.png"
                alt="Logo Left"
                className="lg:w-[190px] lg:h-[190px] w-[70px] h-[70px]"
              />
            </div>
            <div className="text-white lg:ml-[-200px] ml-6 font-bold lg:py-70 py-64 lg:pr-80 lg:mr-80 pr-32 lg:text-9xl text-6xl">
              İletişim
            </div>
      </div>
      <form className="w-full max-w-2xl mt-[50px] mb-80 md:px-0 px-5">

        <div className="flex space-x-4 mb-4">
          <div className="relative w-1/2 border-b-2 mb-16 ">
            <input
              type="text"
              id="ad"

              className="w-full p-2 bg-[#09090A] text-white border-b-2 border-transparent focus:border-white peer"

              required
              placeholder=" "
            />
            <label
              htmlFor="ad"
              className="absolute left-2 -top-4 text-white text-sm transition-all peer-placeholder-shown:text-lg peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-2 peer-focus:text-sm peer-focus:-top-4"
            >
              Ad
            </label>
          </div>
          <div className="relative w-1/2 border-b-2 mb-16 ">
            <input
              type="text"
              id="soyad"

              className="w-full p-2 bg-[#09090A] text-white border-b-2 border-transparent focus:border-white peer"

              required
              placeholder=" "
            />
            <label
              htmlFor="soyad"
              className="absolute left-2 -top-4 text-white text-sm transition-all peer-placeholder-shown:text-lg peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-2 peer-focus:text-sm peer-focus:-top-4"
            >
              Soyad
            </label>
          </div>
        </div>
        <div className="flex space-x-4 mb-4">
          <div className="relative w-1/2 border-b-2 mb-16 ">
            <input
              type="text"
              id="eposta"

              className="w-full p-2 bg-[#09090A] text-white border-b-2 border-transparent focus:border-white peer"

              required
              placeholder=" "
            />
            <label
              htmlFor="eposta"
              className="absolute left-2 -top-4 text-white text-sm transition-all peer-placeholder-shown:text-lg peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-2 peer-focus:text-sm peer-focus:-top-4"
            >
              E-posta
            </label>
          </div>

          <div className="relative w-1/2 border-b-2 mb-16 ">
            <input
              type="text"
              id="telefon"

              className="w-full p-2 bg-[#09090A] text-white border-b-2 border-transparent focus:border-white peer"

              required
              placeholder=" "
            />
            <label
              htmlFor="telefon"
              className="absolute left-2 -top-4 text-white text-sm transition-all peer-placeholder-shown:text-lg peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-2 peer-focus:text-sm peer-focus:-top-4"
            >
              Telefon
            </label>
          </div>
        </div>

        <div className="relative mb-6 border-b-2">
          <textarea
            id="mesaj"

            className="w-full p-2 bg-[#09090A] text-white border-b-2 border-transparent focus:border-white peer h-32"

            required
            placeholder=" "
          />
          <label
            htmlFor="mesaj"
            className="absolute left-2 -top-4 text-white text-sm transition-all peer-placeholder-shown:text-lg peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-2 peer-focus:text-sm peer-focus:-top-4"
          >
            Mesaj
          </label>
        </div>

        <div className="flex justify-center mt-6">
            <button
              type="submit"
              className="relative text-white text-lg flex justify-center items-center transition-all duration-300 ease-out rounded-[30px] w-[900px] h-[60px] bg-gradient-to-r from-[#BA00FF] to-[#3CA0FF] p-[2px]"
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <div
                className={`${

                  hovered ? "bg-[#09090A]" : "bg-transparent"

                } w-full h-full flex justify-center items-center transition-all duration-300 rounded-[30px]`}
              >
                Gönder
              </div>
            </button>
          </div>

      </form>
    </div>
  )
}

export default Contact
 