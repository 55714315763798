import React from "react"
import "./App.css"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Home from "./pages/home"
import About from "./pages/about"
import Projects from "./pages/projects"
import Competitions from "./pages/competitions"
import JoinUsForm from "./pages/JoinUsForm"
import Contact from "./pages/contact"
import Footer from "./components/footer"
import Navbar from "./components/navbar"


function App() {
  return (

    <Router>
      <Navbar></Navbar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/competitions" element={<Competitions />} />
       
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer></Footer>
    </Router>
  )
}

export default App
