import React, { useState } from 'react';
import Hero from '../components/hero';
import Card from '../components/card';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Announcements = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };

    return (
        <div className="bg-[#09090A] text-white py-8 mt-0">
            <div className="container mx-auto px-4">
                <h2 className="font-bold text-3xl md:text-8xl" style={{
                    fontWeight: '600',
                    lineHeight: '1',
                    textAlign: 'left',
                    marginBottom: '20px',
                }}>
                    Duyurular
                </h2>
            
                <div className="flex flex-col md:flex-row-reverse md:gap-8">
                    <div
                        className="w-full md:w-[70%] max-w-[1105px] max-h-[696px] bg-black border-[6px] mb-4"
                        style={{
                            borderImage: 'linear-gradient(111.64deg, #BA00FF 0%, #3CA0FF 100%) 1',
                        }}
                    >
                        <Slider {...settings}>

                            <a href="https://www.instagram.com/p/C5i_DZntAwT/?igsh=MTBmcHh0MWI4cDlzag%3D%3D" target="_blank" rel="noopener noreferrer">
                                <div className="flex flex-col justify-end h-full">
                                    <div className="w-full flex-1 flex justify-center items-center">
                                        <img
                                            src="announcement-photos/announcement-1.png"
                                            alt="Announcement 1"
                                            className="w-full object-cover max-h-[543px] md:max-h-[543px] h-[200px] md:h-auto"
                                        />
                                    </div>
                                    <div className="w-full p-6 bg-black text-white text-lg font-semibold text-left">
                                        Teknofestte 12 Takım ile biz de varız !
                                    </div>
                                </div>
                            </a>

                            <a href="https://www.instagram.com/p/DAA9Q1zNZmy/?igsh=MXZiaTF2MHBxanU1Ng==" target="_blank" rel="noopener noreferrer">
                                <div className="flex flex-col justify-end h-full">
                                    <div className="w-full flex-1 flex justify-center items-center">
                                        <img
                                            src="/assets/geyik.PNG"
                                            alt="Announcement 2"
                                            className="w-full object-cover max-h-[543px] md:max-h-[543px] h-[200px] md:h-auto" 
                                        />
                                    </div>
                                    <div className="w-full p-6 bg-black text-white text-lg font-semibold text-left">
                                        Mobil uygulamamız 'GEYİK' 5000 kullanıcıyı geçti!
                                    </div>
                                </div>
                            </a>

                            <a href="https://example.com/announcement2" target="_blank" rel="noopener noreferrer">
                                <div className="flex flex-col justify-end h-full">
                                    <div className="w-full flex-1 flex justify-center items-center">
                                        <img
                                            src="/temp.png"
                                            alt="Announcement 2"
                                            className="w-full object-cover max-h-[543px] md:max-h-[543px] h-[200px] md:h-auto" 
                                        />
                                    </div>
                                    <div className="w-full p-6 bg-black text-white text-lg font-semibold text-left">
                                        ACM Ar-Ge Web Sitemiz Yenilendi: Yeni Özellikleri Keşfet!
                                    </div>
                                </div>
                            </a>

                            <a href="https://example.com/announcement3" target="_blank" rel="noopener noreferrer">
                                <div className="flex flex-col justify-end h-full">
                                    <div className="w-full flex-1 flex justify-center items-center">
                                        <img
                                            src="/assets/join.png"
                                            alt="Announcement 3"
                                            className="w-full object-cover max-h-[543px] md:max-h-[543px] h-[200px] md:h-auto" 
                                        />
                                    </div>
                                    <div className="w-full p-6 bg-black text-white text-lg font-semibold text-left">
                                        ACM Ar-Ge Takımımız için Başvurular Başladı!
                                    </div>
                                </div>
                            </a>

                        </Slider>
                    </div>

                    <div
                        onClick={() => window.open("https://www.acmhacettepe.com", "_blank")}
                        className="w-full md:max-w-[443px] max-h-[631px] bg-gradient-to-r from-[#7712B6] to-[#B14BDB] flex justify-center items-center cursor-pointer mb-4 md:mb-0"
                    >
                        <img
                            src="logos/navbar-about-home/acmhacettepe-logo.png"
                            alt="ACM Hacettepe Logo"
                            className="w-2/5 h-auto max-h-[543px]"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
const Projects = () => {
    const [hovered, setHovered] = useState(false);

    return (

        <div className="bg-[#09090A] text-white py-16 px-7">

            <div className="container mx-auto">
                <h2 className="font-bold text-3xl md:text-8xl" style={{
                    fontWeight: '600',
                    lineHeight: '1',
                    textAlign: 'left',
                    marginBottom: '20px',
                }}>
                    Projelerimiz
                </h2>


                <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-8">

                    <Card
                        name="Geyik"
                        image="/card-images/projects/geyik-tanitim.png"
                        link="/projects/project1"
                    />
                    <Card
                        name="Tenis Rezervasyon Sistemi"
                        image="/temp.png"
                        link="/projects/project2"
                    />
                    <div className="hidden md:block">
                        <Card
                            name="TOONINGO"
                            image="/temp.png"
                            link="/projects/project3"
                        />
                    </div>
                </div>

                <div className="mt-5 flex justify-end">
                    <button
                        className="text-white text-lg flex justify-center items-center transition-all duration-300 ease-out relative"
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        style={{
                            width: '543px',
                            height: '62px',
                            padding: '2px',
                            borderRadius: '14px',
                            background: 'linear-gradient(105.34deg, rgba(186, 0, 255, 1) 0%, rgba(60, 160, 255, 1) 100%)',
                            position: 'relative',
                            transition: 'background 300ms ease-in-out',
                            fontSize: '21px',
                            fontWeight: '600',
                        }}
                        onClick={() => window.location.href = "/projects"}
                    >
                        <div
                            style={{
                                background: hovered ? 'black' : 'transparent',
                                width: '100%',
                                height: '100%',
                                borderRadius: '14px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: 'background 300ms ease-in-out',
                            }}
                        >
                            Bütün Projelerimize Göz At
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

const Competitions = () => {
    const [hovered, setHovered] = useState(false);

    return (

        <div className="bg-[#09090A] text-white py-16 px-7">

            <div className="container mx-auto">
                <h2 className="font-bold text-3xl md:text-8xl" style={{
                    fontWeight: '600',
                    lineHeight: '1',
                    textAlign: 'left',
                    marginBottom: '20px',
                }}>
                    Ekiplerimiz
                </h2>


                <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-8">

                    <Card
                        name="Teknofest Türkçe Doğal Dil İşleme Takımı: Thoth"
                        image="/card-images/competitions/dogal-dil-isleme.png"
                        link="/competitions/competition1"
                    />

                    <Card
                        name="Teknofest Sağlıkta Yapay Zeka Takımı: Asclepiius"
                        image="/card-images/competitions/saglikta-yapay.png"
                        link="/competitions/competition2"
                    />
                    <div className="hidden md:block">
                        <Card
                            name="Teknofest Model Uydu Takımı: Galactus"
                            image="/card-images/competitions/model-uydu.png"
                            link="/competitions/competition3"
                        />
                    </div>
                </div>

                <div className="mt-5 flex justify-end">
                    <button
                        className="text-white text-lg flex justify-center items-center transition-all duration-300 ease-out relative"
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        style={{
                            width: '543px',
                            height: '62px',
                            padding: '2px',
                            borderRadius: '14px',
                            background: 'linear-gradient(105.34deg, rgba(186, 0, 255, 1) 0%, rgba(60, 160, 255, 1) 100%)',
                            position: 'relative',
                            transition: 'background 300ms ease-in-out',
                            fontSize: '21px',
                            fontWeight: '600',
                        }}
                        onClick={() => window.location.href = "/competitions"}
                    >
                        <div
                            style={{
                                background: hovered ? 'black' : 'transparent',
                                width: '100%',
                                height: '100%',
                                borderRadius: '14px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: 'background 300ms ease-in-out',
                            }}
                        >
                            Tüm Ekiplerimize Göz At
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

const Home = () => {
    return (
        <div className="overflow-x-hidden">
            <Hero />
            <Announcements />
            <Projects />
            <Competitions />
        </div>
    );
};

export default Home;