//navbar and footer will be added later.
import { useState } from "react"
import Card from "../components/card"

export default function Competitions() {
  const allCompetitions = [
    
    {
      year: "2022",
      competitions: [
        { name: "Lorem İpsum2022", image: "/temp.png", link: "" },
        { name: "Lorem İpsum1", image: "/temp.png", link: "" },
        { name: "Lorem İpsum2", image: "/temp.png", link: "" },
        { name: "Lorem İpsum3", image: "/temp.png", link: "" },
        { name: "Lorem İpsum", image: "/temp.png", link: "" },
        { name: "Lorem İpsum", image: "/temp.png", link: "" },
        { name: "Lorem İpsum", image: "/temp.png", link: "" },
        { name: "Lorem İpsum", image: "/temp.png", link: "" },
        { name: "Lorem İpsum", image: "/temp.png", link: "" },
        { name: "Lorem İpsum", image: "/temp.png", link: "" },
        { name: "Lorem İpsum", image: "/temp.png", link: "" },
        { name: "Lorem İpsum", image: "/temp.png", link: "" },
      ],
    },
    {
      year: "2023",
      competitions: [
        {
          name: "Galactus",
          image: "/card-images/competitions/model-uydu.png",
          link: "",
        },
        {
          name: "ASCLEPIIUS",
          image: "/card-images/competitions/saglikta-yapay.png",
          link: "",
        },
        {
          name: "OctoLama",
          image: "/card-images/competitions/saglikta-yapay.png",
          link: "",
        },
        {
          name: "Orbitty",
          image: "/card-images/competitions/ucan-araba.png",
          link: "",
        },
        {
          name: "Han-Yolo",
          image: "/card-images/competitions/ulasimda-yapay.png",
          link: "",
        },
        {
          name: "ComeniusTech",
          image: "/card-images/competitions/egitim-teknolojileri.png",
          link: "",
        },
        {
          name: "BlockChange",
          image: "/card-images/competitions/finansal-teknolojiler.png",
          link: "",
        },
        {
          name: "Anteros",
          image: "/card-images/competitions/psikoloji.png",
          link: "",
        },
        {
          name: "Yüksek İrtifa",
          image: "/card-images/competitions/travel.png",
          link: "",
        },
        {
          name: "SkyDeer",
          image: "/card-images/competitions/travel.png",
          link: "",
        },
        {
          name: "Geyik",
          image: "/card-images/competitions/dogal-dil-isleme.png",
          link: "",
        },
        {
          name: "Thoth",
          image: "/card-images/competitions/dogal-dil-isleme.png",
          link: "",
        },
        {
          name: "YULAF",
          image: "/card-images/competitions/GSC.png",
          link: "",
        },
        {
          name: "Inform Me",
          image: "/card-images/competitions/GSC.png",
          link: "",
        },
        {
          name: "Pari",
          image: "/card-images/competitions/GSC.png",
          link: "",
        },
        {
          name: "Capsule",
          image: "/card-images/competitions/GSC.png",
          link: "",
        },
        {
          name: "ECOS",
          image: "/card-images/competitions/GSC.png",
          link: "",
        },
      ],
    },
    {
      year: "2024",
      competitions: [
        {
          name: "Anatolian Echoes",
          image: "/card-images/competitions/t3.png",
          link: "",
        },
        {
          name: "DeerSync",
          image: "/card-images/competitions/t3.png",
          link: "",
        },
      ],
    },
  ]

  const [date, setDate] = useState("2024")

  const changeDate = (newDate) => {
    setDate(newDate)
    console.log("", newDate)
  }

  const currentCompetitions = allCompetitions.find(
    (competition) => competition.year === date
  )

  return (
    <div
      id="competitions"

      className="min-w-screen min-h-screen bg-[#09090A] text-white pb-12 px-4"

    >
      <main className="py-4 flex flex-col justify-center overflow-hidden">
        <section
          id="dates"
          className="hidden md:block font-bold text-2xl bg-gradient-to-r from-[#BA00FF] to-[#3CA0FF] py-0.5"
        >

          <div className="bg-[#09090A] p-1 pl-72">

            
            <button
              onClick={() => changeDate("2022")}
              className={`mr-8 ${date === "2022" ? "" : "text-gray-600"}`}
            >
              2022
            </button>
            <button
              onClick={() => changeDate("2023")}
              className={`mr-8 ${date === "2023" ? "" : "text-gray-600"}`}
            >
              2023
            </button>
            <button
              onClick={() => changeDate("2024")}
              className={`mr-8 ${date === "2024" ? "" : "text-gray-600"}`}
            >
              2024
            </button>
          </div>


        </section>
        
        <div className="relative flex items-center">
            <div className="absolute lg:left-[140px] lg:top-[130px] left-8 top-[237px] transform translate-y-0 z-10">
              <img
                src="/assets/medal.svg"
                alt="Logo Left"
                className="lg:w-[130px] lg:h-[130px] w-[60px] h-[60px]"
              />
            </div>
            <div className="absolute lg:left-[420px] lg:top-[360px] left-[150px] top-[330px] transform translate-y-0 z-10">
              <img
                src="/assets/cup.svg"
                alt="Logo Left"
                className="lg:w-[190px] lg:h-[190px] w-[70px] h-[70px]"
              />
            </div>
            <section
              id="header"
              className="lg:ml-20 ml-4 font-bold lg:py-40  lg:mb-20 py-64 lg:pr-56 lg:mr-56 pr-32 lg:text-9xl text-5xl "
            >
              Yarışma Ekiplerimiz
            </section>
        </div>
          <section
            id="content"
            className="grid grid-cols-1 lg:grid-cols-3 xs:grid-cols-2 lg:gap-12 gap-4 mx-auto lg:px-0 md:px-0 xs:px-2"

          >
            {currentCompetitions &&
              currentCompetitions.competitions.map((competition) => (
                <Card
                  name={competition.name}
                  image={competition.image}
                  link={competition.link}
                ></Card>
              ))}
          </section>
        <section
          id="mobile-dates"
          className="m-auto mt-12 md:hidden block font-bold text-2xl py-0.5"
        >

          <div className="bg-[#09090A] p-1">

            <button
              onClick={() => changeDate("2024")}
              className={` ${
                date === "2024"
                  ? "bg-gradient-to-br from-[#BA00FF] to-[#3CA0FF] p-4 rounded-2xl"
                  : ""
              } w-16 h-16`}
            >
              24
            </button>
            <button
              onClick={() => changeDate("2023")}
              className={`${
                date === "2023"
                  ? "bg-gradient-to-br from-[#BA00FF] to-[#3CA0FF] p-4 rounded-2xl"
                  : ""
              } w-16 h-16`}
            >
              23
            </button>
            <button
              onClick={() => changeDate("2022")}
              className={`${
                date === "2022"
                  ? "bg-gradient-to-br from-[#BA00FF] to-[#3CA0FF] p-4 rounded-2xl"
                  : ""
              } w-16 h-16`}
            >
              22
            </button>

          </div>
        </section>
      </main>
    </div>
  )
}
